<template>
  <ion-content class="ion-padding caution">
    <table>
      <tr>
        <td width="25%" valign="top">
          <div style="padding: 10px">
            <img src="../../../public/assets/icon/exclamacion.svg" />
          </div>
        </td>
        <td>
          <div style="padding: 10px 0 5px 10px">
            <span class="cautionTitle"> ¿Estás seguro? </span>
          </div>

          <div style="padding: 0 10px">
            <span class="cautionContent">
              Una vez eliminada tu cuenta se borrarán todos los datos ingresados.
            </span>
          </div>
        </td>
        <td width="10%" valign="top">
          <div style="padding-top: 10px; cursor: pointer;">
            <img
              src="../../../public/assets/icon/closeModal.svg"
              v-on:click="cancel"
            />
          </div>
        </td>
      </tr>
      <tr>
        <td width="25%" valign="top"></td>
        <td>
          <table>
            <tr>
              <td>
                <div style="padding: 15px 10px 0px 10px">
                  <span
                    class="cautionContent"
                    style="letter-spacing: 1.5px"
                    v-on:click="cancel"
                  >
                    CANCELAR
                  </span>
                </div>
              </td>
              <td>
                <div style="padding: 15px 10px 0px 10px">
                  <span
                    class="cautionContent"
                    style="letter-spacing: 1.5px"
                    v-on:click="goToBye"
                  >
                    ELIMINAR
                  </span>
                </div>
              </td>
            </tr>
          </table>
        </td>
        <td width="10%"></td>
      </tr>
    </table>
  </ion-content>

</template>

<script>
import { IonContent, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import router from "../../router/index";
import AccountService from "../../services/account.service";

export default defineComponent({
  name: "ModalEliminarCuenta",
  data() {
    return {};
  },
  components: { IonContent },
  methods: {
    cancel() {
      const modal = modalController;
      return modal.dismiss();
    },
    goToBye () {
      const modal = modalController;
      AccountService.deleteAccount().then((data) => {
        if (data === "success") {
          localStorage.removeItem("access_token")
          router.replace("/configuracion/eliminarCuentabye");
          return modal.dismiss();
        }
      })
    }
  },
});
</script>

<style scoped>
table {
  text-align: left;
}
.caution {
  --background: #d46827;
}

.cautionTitle {
  color: white;
  font-family: "Avenir";
  font-size: 20px;
  letter-spacing: 1px;
}

.cautionContent {
  color: white;
  font-family: "Avenir";
  font-size: 12px;
  letter-spacing: 0.75px;
  cursor: pointer;
}
</style>

