<template>
  <ion-page>
    <ion-header>
      <LoggedInNav
        :title="'Eliminar cuenta'"
        :back="true"
        :backUrl="'/configuracion/datosPersonales'"
      />
    </ion-header>
    <ion-grid class="grid" v-if="step1">
      <ion-row>
        <ion-col class="grid__content">
          <h3 class="subtitle-1 ion-text-center">
            Para continuar, primero debes verificar tu identidad introduciendo
            tu contraseña.
          </h3>
        </ion-col>
      </ion-row>
      <ion-row class="w-100">
        <ion-col>
          <form @submit.prevent="enviar" novalidate>
            <div class="centerCenter">
              <ion-item mode="md">
                <ion-label position="floating" class="labelText"
                  >Contraseña</ion-label
                >
                <ion-input
                  :type="passwordFieldType"
                  name="password"
                  v-model="vv.password.$model"
                  v-bind="password"
                />
                <ion-icon
                  slot="end"
                  :icon="passwordEyeStyle"
                  @click="switchPasswordVisibility"
                  style="margin-top: 20px"
                ></ion-icon>
              </ion-item>
            </div>
            <div class="centerCenter">
              <div class="contenedor" v-if="showErrosPassword" style="width: 100%; justify-content: left;">
                <p
                  class="error-msg"
                  v-if="
                    vv?.password?.$errors[0]?.$message === 'Value is required'
                  "
                >
                  Contraseña requerida
                </p>
              </div>
            </div>
              
            

            <div class="centerCenter" style="margin: 50px 0 5px 0">
              <span class="parrafo-small">
                ¿Olvidaste tu contraseña?
                <span
                  style="color: #e33613; font-weight: bold"
                  v-on:click="goToRecover"
                  >RECUPERAR</span
                >
              </span>
            </div>
          </form>
        </ion-col>
      </ion-row>
      <ion-row class="grid__actions">
        <ion-col>
          <div class="centerCenter">
            <ion-button
              class="btn buttonIniciar"
              expand="block"
              fill="solid"
              :disabled="vv?.password.$dirty == false"
              v-on:click="openModal"
              >Siguiente</ion-button
            >
          </div>
          
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import { reactive, toRef } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {
  IonPage,
  IonCol,
  IonGrid,
  IonRow,
  IonHeader,
  IonButton,
  modalController,
  IonLabel,
  IonInput,
  IonItem,
} from "@ionic/vue";
import LoggedInNav from "../../../../components/LoggedInNav.vue";
import Modal from "../../../../components/Modals/ModalEliminarCuenta.vue";
import AccountService from "../../../../services/account.service";
import { eyeOutline, eyeOffOutline } from "ionicons/icons";
import router from "../../../../router/index";
import { useStore } from "vuex";

export default defineComponent({
  name: "EliminarCuenta",
  components: {
    IonPage,
    IonCol,
    IonGrid,
    IonRow,
    IonHeader,
    IonButton,
    LoggedInNav,
    IonLabel,
    IonInput,
    IonItem,
  },
  data() {
    return {
      step1: true,
      password: "",
      passwordFieldType: "password",
      passwordEyeStyle: eyeOffOutline,
      showErrosPassword: false,
      enviar1Button: false,
    };
  },
  setup() {
    const fform = reactive({
      password: "",
    });
    const rules = {
      password: { required },
    };
    const vv = useVuelidate(rules, {
      password: toRef(fform, "password"),
    });

    const onSubmit = () => {
      vv.value.$touch();
      if (vv.value.$invalid) return 0;
    };

    return {
      onSubmit,
      vv,
      eyeOutline,
      eyeOffOutline,
      fform,
    };
  },
  methods: {
    switchPasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.passwordEyeStyle =
        this.passwordEyeStyle === eyeOffOutline ? eyeOutline : eyeOffOutline;
    },
    enviar1() {
      (this.step1 = false), (this.step2 = true);
    },
    async openModal() {
      this.showErrosPassword = true;
      if (this.vv.password?.$errors.length === 0) {
        const modal = await modalController.create({
          component: Modal,
          mode: "md"
        });
        AccountService.checkPassword({
          password: this.fform.password,
        }).then((data) => {
          if (data === "success") {
            return modal.present();
          }
        });
      }
    },
    goToRecover() {
      router.push("/recuperarPassword");
    },
    cerrar() {
      router.push("/");
    },
  },
});
</script>
<style lang="scss" scoped>
.grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 38px 14px 35px;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
  }

  &__actions {
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    align-items: flex-end;
  }
}

.label {
  display: flex;
  align-items: center;

  img {
    margin-right: 20px;
  }

  h5 {
    margin: 0;
  }
}

ion-item {
  border-radius: 10px 10px 0 0;
  border-bottom: 3px solid #b4b4b4;
  --background: white;
  margin-top: 10px;
  --highlight-color-focused: #d4692700;
  width: 100%;
  max-width: 700px;
}

ion-item.item-has-focus {
  border-bottom: 3px solid #d46927;
}

ion-item.item-has-focus > ion-label {
  color: #d46827;
} 
</style>
